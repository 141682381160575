<template>
  <div class="page-wrapper theme-green-forest forgot-password-wrapper">
    <div class="logo-container">
      <router-link to="/">
        <img src="@/assets/icons/logo.svg" alt="logo" />
      </router-link>
    </div>

    <div class="login top-96">
      <div class="login__title account-title">reset Password</div>
      <div class="login__form-wrapper account-form-wrapper forgot-password">
        <form class="login__form" @submit.prevent="onSubmit">
          <div class="login__inputs">
            <div class="login__input-wrapper input-wrapper">
              <input
                type="password"
                placeholder="Enter new password"
                v-model="password"
                class="with-icon"
                required
              />
            </div>
            <div class="login__input-wrapper input-wrapper">
              <input
                type="password"
                placeholder="Confirm new password"
                v-model="confirmPassword"
                class="with-icon"
                required
              />
              <p
                class="login__input-error error-message"
                v-if="!isPasswordConfirm"
              >
                Confirm your password
              </p>
            </div>
            <div class="input-wrapper--login">
              <button type="submit">
                <span class="text">Confirm</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { userUpdatePasswordByResetLink } from '@/services/user.service'
import { notification } from 'ant-design-vue'
export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      confirmPassword: '',
    }
  },
  computed: {
    isPasswordConfirm() {
      return this.password === this.confirmPassword
    },
  },
  methods: {
    onSubmit() {
      if (!this.isPasswordConfirm) return
      const data = {
        token: this.$route.query.token,
        email: this.$route.query.email,
        password: this.password,
      }
      userUpdatePasswordByResetLink(data)
        .then(() => {
          notification.success({ message: 'Successfully updated!' })
          this.$router.push('/login')
        })
        .catch((err) => {
          // notification.error({ message: err.message })
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
